import { graphql } from "gatsby";
import React from "react";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["common"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Privacy() {
    return (
        <Layout>
            <Container>
                <h1>Informativa sulla Privacy e Cookie Policy</h1>

                <Separator />

                <h2>Privacy Policy</h2>
                <p>
                    Questo Sito raccoglie alcuni dati personali dei propri
                    utenti.
                </p>
                <h4>Titolare del trattamento dei dati</h4>
                <p>
                    Albergo Ristorante Moderno di Nasini Carlo &amp; Cecconi
                    Mirella snc - Via Giosuè Carducci, 37, Santa Maria degli
                    Angeli (PG), 06081 - Italy. E-mail:{" "}
                    <a
                        href="mailto:info@hotelmodernoassisi.com"
                        className="underline"
                    >
                        info@hotelmodernoassisi.com
                    </a>
                </p>
                <h4>Tipologie di dati raccolti</h4>
                <p>
                    Fra i dati personali raccolti da questo Sito, in modo
                    autonomo o tramite terze parti, vi sono: cookie, dati di
                    utilizzo, e-mail, nome.
                </p>
                <p>
                    Altri dati personali raccolti potrebbero essere indicati in
                    altre sezioni di questa Privacy Policy o mediante testi
                    informativi visualizzati contestualmente alla raccolta dei
                    dati stessi.
                </p>
                <p>
                    {" "}
                    I dati personali possono essere inseriti volontariamente
                    dall’Utente, oppure raccolti in modo automatico durante
                    l’uso di questo Sito.
                </p>
                <p>
                    {" "}
                    L’eventuale utilizzo di cookie - o di altri strumenti di
                    tracciamento - da parte di questo Sito o dei titolari dei
                    servizi terzi utilizzati da questo Sito, ove non
                    diversamente precisato, ha la finalità di identificare
                    l’Utente e registrare le relative preferenze per finalità
                    strettamente legate all’erogazione del servizio richiesto
                    dall’Utente.
                </p>
                <p>
                    {" "}
                    Il mancato conferimento da parte dell’Utente di alcuni dati
                    personali potrebbe impedire a questo Sito di erogare i
                    propri servizi.
                </p>
                <p>
                    L’Utente si assume la responsabilità dei dati personali di
                    terzi pubblicati o condivisi mediante questo Sito e
                    garantisce di avere il diritto di comunicarli o diffonderli,
                    liberando il Titolare da qualsiasi responsabilità verso
                    terzi.
                </p>

                <h3>Modalità e luogo del trattamento dei dati raccolti</h3>
                <h4>Modalità di trattamento</h4>
                <p>
                    Il Titolare tratta i dati personali degli Utenti adottando
                    le opportune misure di sicurezza volte ad impedire
                    l’accesso, la divulgazione, la modifica o la distruzione non
                    autorizzate dei dati personali.
                </p>
                <p>
                    {" "}
                    Il trattamento viene effettuato mediante strumenti
                    informatici e/o telematici, con modalità organizzative e con
                    logiche strettamente correlate alle finalità indicate. Oltre
                    al Titolare, in alcuni casi, potrebbero avere accesso ai
                    dati categorie di incaricati coinvolti nell’organizzazione
                    del sito (personale amministrativo, commerciale, marketing,
                    legali, amministratori di sistema) ovvero soggetti esterni
                    (come fornitori di servizi tecnici terzi, corrieri postali,
                    hosting provider, società informatiche, agenzie di
                    comunicazione) nominati anche, se necessario, Responsabili
                    del Trattamento da parte del Titolare. L’elenco aggiornato
                    dei Responsabili potrà sempre essere richiesto al Titolare
                    del Trattamento.
                </p>
                <h4>Luogo</h4>
                <p>
                    I dati sono trattati presso la sede operativa del Titolare.
                    Per ulteriori informazioni, contatta il Titolare.
                </p>
                <h4>Tempi</h4>
                <p>
                    I dati sono trattati per il tempo necessario allo
                    svolgimento del servizio richiesto dall’Utente, richiesto
                    dalle finalità descritte in questo documento e comunque fino
                    a un massimo di anni 10 (dieci), e l’Utente può sempre
                    chiedere l’interruzione del Trattamento o la cancellazione
                    dei dati.
                </p>
                <p>
                    <b>Finalità del Trattamento dei dati raccolti</b>
                </p>
                <p>
                    I dati dell’Utente sono raccolti per consentire al sito di
                    fornire i propri servizi, così come per le seguenti
                    finalità: contattare l’Utente, gestione indirizzi e invio di
                    messaggi email, interazione con social network e piattaforme
                    esterne, affiliazione commerciale, commento dei contenuti e
                    statistica.
                </p>
                <p>
                    Le tipologie di dati personali utilizzati per ciascuna
                    finalità sono indicati nelle sezioni specifiche di questo
                    documento.
                </p>

                <h3>Dettagli sul trattamento dei dati personali</h3>
                <p>
                    I dati personali sono raccolti per le seguenti finalità ed
                    utilizzando i seguenti servizi:
                </p>
                <h4>Contattare l’Utente</h4>
                <p>
                    <b>Modulo di contatto (questo Sito)</b>
                </p>
                <p>
                    L’Utente, compilando con i propri dati il modulo di
                    contatto, acconsente al loro utilizzo per rispondere alle
                    richieste di informazioni, di preventivo, o di qualunque
                    altra natura indicata dall’intestazione del modulo.
                </p>
                <p>Dati personali raccolti: Email, Nome, numero di telefono.</p>
                <h4>Protezione spam, malware e prevenzione attacchi</h4>
                <p>
                    Questi servizi analizzano il traffico di questo Sito,
                    potenzialmente contenente dati personali degli Utenti, al
                    fine di filtrarlo da parti di traffico, messaggi e contenuti
                    riconosciuti come SPAM.
                </p>
                <p>
                    <b>reCaptcha (Google Inc.)</b>
                </p>
                <p>
                    Al fine di proteggere i dati che inserisci tramite i
                    formulari di questo sito, utilizziamo il servizio reCaptcha
                    di Google Inc. (Google). Questo servizio verifica che tu sia
                    una persona per evitare che alcune delle funzioni del sito
                    vengano (ab)usate da parte di robot spam. L’utilizzo di
                    questa funzione implica che il tuo indirizzo IP e gli altri
                    dati richiesti da Google per il servizio Google reCaptcha.
                    In ogni caso, all’interno degli stati membri dell’Unione
                    Europea e dello Spazio Economico Europeo l’indirizzo IP
                    viene troncato e reso anonimo prima della trasmissione da
                    parte di Google. Solo in casi eccezionali l’indirizzo IP
                    viene inviato per intero ad uno dei server di Google negli
                    Stati Uniti e troncato una volta ricevuto. Google utilizza
                    poi queste informazioni per verificare a nome nostro come
                    stai utilizzando il nostro sito. L’indirizzo IP fornito da
                    reCaptcha non verrà aggregato con nessun altro dato in
                    possesso a Google. La raccolta di questo dato è soggetta
                    alle norme in materia di protezione dei dati di Google
                    (Google Inc.). Utilizzando il servizio reCaptcha autorizzi
                    Google a processare i tuoi dati allo scopo e nella maniera
                    di cui sopra.
                </p>
                <p>
                    Luogo del trattamento: USA -{" "}
                    <a
                        href="https://policies.google.com/privacy?hl=i"
                        className="underline"
                    >
                        Privacy Policy
                    </a>
                </p>
                <h4>Statistica</h4>
                <p>
                    I servizi contenuti nella presente sezione permettono al
                    Titolare del Trattamento di monitorare e analizzare i dati
                    di traffico e servono a tener traccia del comportamento
                    dell’Utente.
                </p>
                <p>
                    <b>Google Analytics (Google Inc.)</b>
                </p>
                <p>
                    Google Analytics è un servizio di analisi web fornito da
                    Google Inc. (“Google”). Google utilizza i dati personali
                    raccolti allo scopo di tracciare ed esaminare l’utilizzo di
                    questo Sito, compilare report e condividerli con gli altri
                    servizi sviluppati da Google. Google potrebbe utilizzare i
                    dati personali per contestualizzare e personalizzare gli
                    annunci del proprio network pubblicitario. Nel presente
                    Sito, Google Analytics non raccoglie dati demografici di
                    nessun tipo ed è presente l’Anonimizzazione dell’IP.
                </p>
                <p>
                    Al seguente link{" "}
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=it"
                        className="underline"
                    >
                        https://tools.google.com/dlpage/gaoptout?hl=it
                    </a>{" "}
                    è inoltre reso disponibile da Google il componente
                    aggiuntivo del browser per la disattivazione di Google
                    Analytics.
                </p>
                <p>Dati personali raccolti: cookie e dati di utilizzo.</p>
                <p>
                    Luogo del trattamento: USA -{" "}
                    <a
                        href="https://www.google.com/intl/it/policies/privacy/"
                        className="underline"
                    >
                        Privacy Policy
                    </a>{" "}
                    -{" "}
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=it"
                        className="underline"
                    >
                        Opt Out
                    </a>
                </p>
                <h4>Commento dei contenuti</h4>
                <p>I commenti sono disabilitati su questo sito.</p>
                <h4>Interazione con Social Networks</h4>
                <p>
                    Non ci sono modalità di interazione diretta con i social
                    network su questo sito.
                </p>
                <h4>Contenuti su piattaforme esterne</h4>
                <p>
                    Questi servizi permettono di visualizzare contenuti ospitati
                    su piattaforme esterne direttamente dalle pagine di questo
                    Sito e di interagire con essi.
                </p>
                <p>
                    Nel caso in cui sia installato un servizio di questo tipo, è
                    possibile che, anche nel caso gli Utenti non utilizzino il
                    servizio, lo stesso raccolga dati di traffico relativi alle
                    pagine in cui è installato.
                </p>
                <p>
                    <b>Google Maps (Google Inc.)</b>
                </p>
                <p>
                    Google Maps è un servizio di visualizzazione di mappe
                    gestito da Google Inc. che permette a questo Sito di
                    integrare tali contenuti all’interno delle proprie pagine.
                </p>
                <p>Dati personali raccolti: cookie e dati di utilizzo</p>
                <p>
                    Luogo del trattamento: USA -{" "}
                    <a
                        href="https://www.google.it/intl/it/policies/privacy/"
                        className="underline"
                    >
                        Privacy Policy
                    </a>
                </p>

                <Separator />

                <h2>Cookie Policy</h2>
                <p>Questo sito web utilizza i cookie.</p>
                <p>
                    Si utilizzano i cookie per personalizzare i contenuti,
                    fornire le funzioni dei social media e analizzare il
                    traffico del Sito. Inoltre potrebbero venire informazioni
                    sul modo in cui il Sito viene utilizzato a partner che si
                    occupano di analisi dei dati web, pubblicità e social media,
                    i quali potrebbero combinarle con altre informazioni che
                    l’Utente ha fornito loro in base al consenso sull’utilizzo
                    dei loro servizi.
                </p>
                <p>
                    I cookie sono piccoli file di testo che possono essere
                    utilizzati dai siti web per rendere più efficiente
                    l’esperienza per l’utente.
                </p>
                <p>
                    Questo sito utilizza diversi tipi di cookie. Alcuni cookie
                    sono posti da servizi terzi.
                </p>
                <p>
                    Il consenso si applica ai seguenti siti web:
                    www.hotelmodernoassisi.com
                </p>

                <h3>Cookie tecnici</h3>
                <p>
                    I cookie necessari aiutano a contribuire a rendere fruibile
                    un sito web abilitando le funzioni di base come la
                    navigazione della pagina e l’accesso alle aree protette del
                    sito. Il sito web non può funzionare correttamente senza
                    questi cookie.*
                </p>
                <table className="text-left">
                    <thead className="border-y-2">
                        <tr>
                            <th>Nome</th>
                            <th>Dominio</th>
                            <th>Scopo</th>
                            <th>Durata</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b">
                            <td className="p-2">cookie-consent</td>
                            <td className="p-2">hotelmodernoassisi.com</td>
                            <td className="p-2">
                                Presa visione e consenso della barra dei cookie
                            </td>
                            <td className="p-2">1 anno</td>
                        </tr>
                        <tr className="border-b">
                            <td className="p-2">_gid</td>
                            <td className="p-2">hotelmodernoassisi.com</td>
                            <td className="p-2">
                                Google Analytics (IP anonimizzato)
                            </td>
                            <td className="p-2">1 giorno</td>
                        </tr>
                        <tr className="border-b">
                            <td className="p-2">_ga</td>
                            <td className="p-2">hotelmodernoassisi.com</td>
                            <td className="p-2">
                                Google Analytics (IP anonimizzato)
                            </td>
                            <td className="p-2">2 anni</td>
                        </tr>
                        <tr className="border-b">
                            <td className="p-2">_gat</td>
                            <td className="p-2">hotelmodernoassisi.com</td>
                            <td className="p-2">
                                Google Analytics (IP anonimizzato)
                            </td>
                            <td className="p-2">Sessione</td>
                        </tr>
                        <tr className="border-b">
                            <td className="p-2">NID</td>
                            <td className="p-2">.google.com</td>
                            <td className="p-2">
                                Usato per ricordare le preferenze dell'utente
                            </td>
                            <td className="p-2">6 mesi</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    *Google Analytics con l’Anonimizzazione IP attiva e la
                    raccolta dei dati demografici disabilitata rientra in questa
                    categoria di cookie.
                </p>

                <h3>Cookie per finalità di marketing</h3>
                <p>
                    I singoli cookie di terze parti, nonché i link attraverso i
                    quali l’utente può ricevere maggiori informazioni e
                    richiedere la disattivazione dei cookie sono riportati
                    nell’elenco dei servizi indicati nel paragrafo “Dettagli sul
                    trattamento dei Dati Personali” a cui si rimanda.
                </p>

                <h3>Modalità del trattamento</h3>
                <p>
                    Il trattamento viene effettuato con strumenti automatizzati
                    dal Titolare. Non viene effettuata alcuna diffusione o
                    comunicazione.
                </p>

                <h3>Conferimento dei dati</h3>
                <p>
                    Fatta eccezione per i cookie tecnici strettamente necessari
                    alla normale navigazione, il conferimento dei dati è rimesso
                    alla volontà dell’interessato che decida di navigare sul
                    sito dopo aver preso visione dell’informativa breve
                    contenuta nell’apposito banner e di usufruire dei servizi di
                    terze parti che comportano l’installazione di cookie.
                </p>
                <p>
                    L’interessato può quindi evitare l’installazione dei cookie
                    mantenendo il banner (astenendosi quindi dal chiuderlo
                    cliccando sul tasto “Ok”) nonché attraverso le apposite
                    funzioni disponibili sul proprio browser.
                </p>

                <h3>Stato attuale e revoca del consenso</h3>
                <p>
                    <button
                        className="underline"
                        onClick={() => (document.cookie = "")}
                    >
                        Preferenze cookie
                    </button>
                </p>

                <h3>Disabilitazione dei cookie</h3>
                <p>
                    In aggiunta a quanto indicato in questo documento, l’Utente
                    può gestire le preferenze relative ai Cookie direttamente
                    all’interno del proprio browser ed impedire - ad esempio -
                    che terze parti possano installarne. Tramite le preferenze
                    del browser è inoltre possibile eliminare i Cookie
                    installati in passato, incluso il Cookie in cui venga
                    eventualmente salvato il consenso all’installazione di
                    Cookie da parte di questo sito. È importante notare che
                    disabilitando tutti i Cookie, il funzionamento di questo
                    sito potrebbe essere compromesso.
                </p>
                <p>
                    Ciascun browser presenta procedure diverse per la gestione
                    delle impostazioni. L’utente può ottenere istruzioni
                    specifiche attraverso i link sottostanti.
                </p>
                <p>
                    <a
                        href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                        className="underline"
                    >
                        <b>Microsoft Edge</b>
                    </a>
                </p>
                <p>
                    <a
                        href="https://support.google.com/chrome/bin/answer.py?hl=it&amp;answer=95647&amp;p=cpn_cookies"
                        className="underline"
                    >
                        <b>Google Chrome</b>
                    </a>
                </p>
                <p>
                    <a
                        href="https://support.mozilla.org/it-IT/kb/Enabling%20and%20disabling%20cookies"
                        className="underline"
                    >
                        <b>Mozilla Firefox</b>
                    </a>
                </p>
                <p>
                    <a
                        href="https://support.apple.com/kb/PH17191?viewlocale=it_IT&amp;locale=it_IT"
                        className="underline"
                    >
                        <b>Apple Safari</b>
                    </a>
                </p>
                <p>
                    <a
                        href="http://help.opera.com/Windows/10.00/it/cookies.html"
                        className="underline"
                    >
                        <b>Opera</b>
                    </a>
                </p>
                <p>
                    La disattivazione dei cookie di terze parti è inoltre
                    possibile attraverso le modalità rese disponibili
                    direttamente dalla società terza titolare per detto
                    trattamento, come indicato ai link riportati nel paragrafo
                    “cookie di terze parti”.
                </p>
                <p>
                    In caso di servizi erogati da terze parti, l’Utente può
                    inoltre esercitare il proprio diritto ad opporsi al
                    tracciamento informandosi tramite la privacy policy della
                    terza parte, tramite il link di opt out se esplicitamente
                    fornito o contattando direttamente la stessa.
                </p>

                <h3>Ulteriori informazioni sul trattamento</h3>
                <h4>Difesa in giudizio</h4>
                <p>
                    I Dati Personali dell’Utente possono essere utilizzati per
                    la difesa da parte del Titolare in giudizio o nelle fasi
                    propedeutiche alla sua eventuale instaurazione, da abusi
                    nell’utilizzo della stessa o dei servizi connessi da parte
                    dell’Utente.
                </p>
                <p>
                    L’Utente dichiara di essere consapevole che il Titolare
                    potrebbe essere richiesto di rivelare i Dati su richiesta
                    delle pubbliche autorità.
                </p>

                <h4>Informative specifiche</h4>
                <p>
                    Su richiesta dell’Utente, in aggiunta alle informazioni
                    contenute in questa privacy policy, questo Sito potrebbe
                    fornire all’Utente delle informative aggiuntive e
                    contestuali riguardanti servizi specifici, o la raccolta ed
                    il trattamento di Dati Personali.
                </p>

                <h4>Log di sistema e manutenzione</h4>
                <p>
                    Per necessità legate al funzionamento ed alla manutenzione,
                    questo Sito e gli eventuali servizi terzi da essa utilizzati
                    potrebbero raccogliere Log di sistema, ossia file che
                    registrano le interazioni e che possono contenere anche Dati
                    Personali, quali l’indirizzo IP Utente.
                </p>

                <h4>Informazioni non contenute in questa policy</h4>
                <p>
                    Maggiori informazioni in relazione al trattamento dei Dati
                    Personali potranno essere richieste in qualsiasi momento al
                    Titolare del Trattamento utilizzando le informazioni di
                    contatto.
                </p>

                <h4>Esercizio dei diritti da parte degli Utenti</h4>
                <p>
                    I soggetti cui si riferiscono i Dati Personali hanno il
                    diritto in qualunque momento di ottenere la conferma
                    dell’esistenza o meno degli stessi presso il Titolare del
                    Trattamento, di conoscerne il contenuto e l’origine, di
                    verificarne l’esattezza o chiederne l’integrazione, la
                    cancellazione, l’aggiornamento, la rettifica, la
                    trasformazione in forma anonima o il blocco dei Dati
                    Personali trattati in violazione di legge, nonché di opporsi
                    in ogni caso, per motivi legittimi, al loro trattamento. Le
                    richieste vanno rivolte al Titolare del Trattamento.
                </p>
                <p>
                    Questo Sito non supporta le richieste “Do Not Track”. Per
                    conoscere se gli eventuali servizi di terze parti utilizzati
                    le supportano, consulta le loro privacy policy.
                </p>

                <h4>Modifiche a questa privacy policy</h4>
                <p>
                    Il Titolare del Trattamento si riserva il diritto di
                    apportare modifiche alla presente privacy policy in
                    qualunque momento dandone pubblicità agli Utenti su questa
                    pagina. Si prega dunque di consultare spesso questa pagina,
                    prendendo come riferimento la data di ultima modifica
                    indicata in fondo. Nel caso di mancata accettazione delle
                    modifiche apportate alla presente privacy policy, l’Utente è
                    tenuto a cessare l’utilizzo di questo Sito e può richiedere
                    al Titolare del Trattamento di rimuovere i propri Dati
                    Personali. Salvo quanto diversamente specificato, la
                    precedente privacy policy continuerà ad applicarsi ai Dati
                    Personali sino a quel momento raccolti.
                </p>

                <h4>Informazioni su questa privacy policy</h4>
                <p>
                    Il Titolare del Trattamento dei Dati è responsabile per
                    questa privacy policy.
                </p>

                <h3>Definizioni e riferimenti legali</h3>
                <h4>Dati Personali (o Dati)</h4>
                <p>
                    Costituisce dato personale qualunque informazione relativa a
                    persona fisica, identificata o identificabile, anche
                    indirettamente, mediante riferimento a qualsiasi altra
                    informazione, ivi compreso un numero di identificazione
                    personale.
                </p>

                <h4>Dati di Utilizzo</h4>
                <p>
                    Sono i dati personali raccolti in maniera automatica dal
                    Sito (o dalle applicazioni di parti terze che la stessa
                    utilizza), tra i quali: gli indirizzi IP o i nomi a dominio
                    dei computer utilizzati dall’Utente che si connette al Sito,
                    gli indirizzi in notazione URI (Uniform Resource
                    Identifier), l’orario della richiesta, il metodo utilizzato
                    nel sottoporre la richiesta al server, la dimensione del
                    file ottenuto in risposta, il codice numerico indicante lo
                    stato della risposta dal server (buon fine, errore, ecc.) il
                    Paese di provenienza, le caratteristiche del browser e del
                    sistema operativo utilizzati dal visitatore, le varie
                    connotazioni temporali della visita (ad esempio il tempo di
                    permanenza su ciascuna pagina) e i dettagli relativi
                    all’itinerario seguito all’interno del Sito, con particolare
                    riferimento alla sequenza delle pagine consultate, ai
                    parametri relativi al sistema operativo e all’ambiente
                    informatico dell’Utente.
                </p>

                <h4>Utente</h4>
                <p>
                    L’individuo che utilizza questo Sito, che deve coincidere
                    con l’Interessato o essere da questo autorizzato ed i cui
                    Dati Personali sono oggetto del trattamento.
                </p>

                <h4>Interessato</h4>
                <p>
                    La persona fisica o giuridica cui si riferiscono i Dati
                    Personali.
                </p>

                <h4>Responsabile del Trattamento (o Responsabile)</h4>
                <p>
                    La persona fisica, giuridica, la pubblica amministrazione e
                    qualsiasi altro ente, associazione od organismo preposti dal
                    Titolare al trattamento dei Dati Personali, secondo quanto
                    predisposto dalla presente privacy policy.
                </p>

                <h4>Titolare del Trattamento (o Titolare)</h4>
                <p>
                    La persona fisica, giuridica, la pubblica amministrazione e
                    qualsiasi altro ente, associazione od organismo cui
                    competono, anche unitamente ad altro titolare, le decisioni
                    in ordine alle finalità, alle modalità del trattamento di
                    dati personali ed agli strumenti utilizzati, ivi compreso il
                    profilo della sicurezza, in relazione al funzionamento e
                    alla fruizione di questa Applicazione. Il Titolare del
                    Trattamento, salvo quanto diversamente specificato, è il
                    proprietario di questo Sito.
                </p>

                <h4>Questo Sito</h4>
                <p>
                    Lo strumento hardware o software mediante il quale sono
                    raccolti i Dati Personali degli Utenti.
                </p>

                <h4>Cookie</h4>
                <p>
                    Piccola porzione di dati conservata all’interno del
                    dispositivo dell’Utente.
                </p>

                <h4>Riferimenti legali</h4>
                <p>
                    Avviso agli Utenti europei: la presente informativa privacy
                    è redatta in adempimento degli obblighi previsti dall’Art.
                    10 della Direttiva n. 95/46/CE, nonché a quanto previsto
                    dalla Direttiva 2002/58/CE, come aggiornata dalla Direttiva
                    2009/136/CE, in materia di Cookie.
                </p>
                <p>
                    Questa informativa privacy riguarda esclusivamente questo
                    Sito.
                </p>
                <p>
                    Questa pagina è visibile, mediante link in calce in tutte le
                    pagine del Sito ai sensi dell’art. 122 secondo comma del
                    D.lgs. 196/2003 e a seguito delle modalità semplificate per
                    l’informativa e l’acquisizione del consenso per l’uso dei
                    cookie pubblicata sulla Gazzetta Ufficiale n.126 del 3
                    giugno 2014 e relativo registro dei provvedimenti n.229
                    dell’8 maggio 2014.
                </p>
            </Container>
        </Layout>
    );
}
